<template>
  <div v-loading="loading" class="order-detail-container">
    <div class="first-content">
      <div class="buttonbc">
        <el-button @click="() => { $router.go(-1); }" size="small" class="back_btn">
          <img src="@/assets/back.png" alt="">
        </el-button>
      </div>

      <div class="title_nav">
        <p>
          <span>开票站点：{{ shop_name }}</span>
          <span>发票编号：{{ details.batch_sn }}</span>
          <span>申请时间：{{ details.create_time | unixToDate }}</span>
          <span>开票金额：{{ details.receipt_amount }}</span>
        </p>
        <p>
          <span>开票状态：{{ details.status | status_filter }}</span>
          <span>审核备注：{{ details.reason }}</span>
        </p>
      </div>
      <div class="title_content">
        <div style="width:35%;margin-right:30px;">
          <p style="font-size: 18px; margin:0">开票信息</p>
          <div class="contents">
            <p>发票类型：{{ details.receipt_type === "VATORDINARY" ? "增值税普通发票" : "增值税专用发票" }}</p>
            <p>发票抬头： {{ details.receipt_title }}</p>
            <p>企业税号：{{ details.tax_no }}</p>
            <p>开户银行：{{ details.bank_name }}</p>
            <p>开户账号：{{ details.bank_account }}</p>
            <p>注册地址：{{ details.reg_addr }}</p>
            <p>注册电话：{{ details.reg_tel }}</p>
          </div>
        </div>
        <div style="width:35%;">
          <p style="font-size: 18px;margin:0">寄送地址</p>
          <div class="contents">
            <p>收件人姓名：{{ details.receive_name }}</p>
            <p>手机号：{{ details.receive_phone }}</p>
            <p>详细地址：{{ details.receive_addr }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="secend-content">
      <p style="font-size: 18px;">开票账单</p>
      <div class="tables">
        <el-table :header-cell-style="{ textAlign: 'center', backgroundColor: 'rgba(230, 236, 247, 1)' }"
          :data="tableData" border>
          <el-table-column label="账单年月" width="160">
            <template slot-scope="{row}">{{ row.sn | sn_filter }}</template>
          </el-table-column>
          <el-table-column label="商品总额" prop="goods_total" />
          <el-table-column label="运费总额" prop="shop_freight_price" />
          <el-table-column label="账单总金额" prop="statement_total" />
          <el-table-column label="操作" width="200">
            <template slot-scope="{row}">
              <el-button size="mini" type="primary" @click="getInfoDetail(row)">查看详情</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- <el-pagination background slot="pagination" @size-change="handlePageSizeChange"
        @current-change="handlePageCurrentChange" :current-page="pageData.page_no" :page-sizes="MixinPageSizes"
        :page-size="pageData.page_size" :layout="MixinTableLayout" :total="pageData.data_total">
      </el-pagination> -->
      <div class="cont">
        <p style="font-size: 18px;">物流详情</p>
        <template v-if="!details.logi_name && !details.logi_id">
          （暂无）
        </template>
        <template v-else>
          <el-steps direction="vertical" :active="0">
            <el-step v-for="(item, index) in logisticsData.data" :title="item.context" :description="item.time"
              :key="index"></el-step>
          </el-steps>
        </template>
      </div>
    </div>

  </div>
  <!-- </div> -->
</template>

<script>
import * as API_goods from "@/api/goods";
import * as API_Account from "@/api/account";
import * as reconciliationInvoices from "@/api/reconciliationInvoices";
export default {
  name: "Invoicemangerjiludetail",
  components: {},
  data () {
    return {
      shop_name: '',//开票站点
      is: "",
      id: "",
      details: {},
      paramsData: {
        page_no: 1,
        page_size: 9999,
        accountStatementTotalIds: "",
      },
      tableData: [],
      /** 列表loading状态 */
      loading: false,
      /** 物流信息 */
      logisticsData: [],
    };
  },
  filters: {
    status_filter (data) {
      switch (data) {
        case -1:
          return "已拒绝";
        case 0:
          return "待审核";
        case 1:
          return "已通过";
        default:
          return "已经邮寄";
      }
    },
    sn_filter (value) {
      if (!value) return '';
      console.log(value)
      let str = value?.split('')
      str.splice(4, 0, '年')
      let newValue = str.join('')
      newValue = newValue + '月'
      return newValue
    },
  },
  mounted () {
    this.id = this.$route.query.id;
    this.is = this.$route.query.is;
    this.getInfo();
  },
  methods: {
    getInfo () {
      API_goods.getInfo(this.id).then(res => {
        this.details = res;
        reconciliationInvoices.getShopInfo(res.apply_id).then(datas => {
          this.shop_name = datas.shop_name
        })
        const list = res.account_statement_total_str.split(',');
        this.paramsData.accountStatementTotalIds = list;
        this.getOrderList();
        this.GET_Express();
      });
    },
    /** 分页大小发生改变 */
    handlePageSizeChange (size) {
      this.paramsData.page_no = 1
      this.paramsData.page_size = size;
      this.getOrderList();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange (page) {
      this.paramsData.page_no = page;
      this.getOrderList();
    },
    getOrderList () {
      reconciliationInvoices.getWaitAccountOrderList(this.paramsData).then(response => {
        this.loading = false;
        this.tableData = response.data;
      });
    },
    GET_Express () {
      if (this.details.logi_name && this.details.logi_id && this.details.delivery_no) {
        API_Account.getExpressDetail({
          id: this.details.logi_id, //快递公司
          num: this.details.delivery_no, //物流单号
          mobile: this.details.receive_phone//电话
        }).then(res => {
          this.logisticsData = res;
        });
      }
    },
    /**打开详情页弹窗 */
    getInfoDetail (row = null) {
      this.$router.push({
        name: 'reconciliationInvoicesDetail',
        query: { sn: row.sn, shopId: this.details.apply_id }
      });
    },
  }
};
</script>

<style lang="scss" scoped>
.title_nav {
  margin-top: 10px;

  p {
    span {
      margin-right: 40px;
    }
  }
}

.title_content {
  display: flex;

  .contents {
    background: #f2f2f2;
    height: 261px;
    padding: 15px 10px 1px 10px;
  }
}


.buttonbc {
  float: right;
  height: 40px;

  .back_btn {
    width: 36px;
    height: 36px;
    background-color: #1A43A9;
    border-radius: 50%;

    img {
      width: 10px;
      height: 20px;
      position: relative;
      top: -2px;
      left: -4px;
    }
  }
}

.cont {

  padding-bottom: 20px;
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.zongji {
  width: 100%;
  font-size: 26px;
  text-align: right;
  padding-top: 30px;
}

.sendhuo {
  margin-top: 60px;
  float: right;
}

.goods-image {
  width: 50px;
  height: 50px;
}

/deep/ .el-step__title.is-wait {
  color: #666;
}

.toolbar-title {
  font-size: 17px;
  font-weight: 600;
  position: relative;
  padding-left: 4px;
  margin-right: 12px;
}

.toolbar-title::before {
  content: ' ';
  display: inline-block;
  width: 4px;
  height: 18px;
  background: #1A43A9;
  position: absolute;
  top: 50%;
  left: -12px;
  transform: translate(0, -50%);
}

.order-detail-container {
  background: transparent;
  position: relative;
  border-radius: 5px;
}

.first-content {
  background: #fff;
  margin-bottom: 20px;
  padding: 20px;
  padding-top: 10px;

}

.secend-content {
  background: #fff;
  padding: 20px;
}
</style>
